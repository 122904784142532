<template>
  <content-with-sidebar
    class="cws-container cws-sidebar-right mt-3 blog-wrapper"
  >
    <b-overlay :show="loading">
      <b-alert
        variant="danger"
        :show="blogPost === undefined && !loading"
      >
        <h4 class="alert-heading">
          Error fetching Blog Post Details
        </h4>
        <div class="alert-body">
          <b-link
            class="alert-link"
            :to="{ name: 'admin-blogs'}"
          >
            List Blogs
          </b-link>
        </div>
      </b-alert>

      <blog-details v-if="blogPost" :blog-post="blogPost" @refresh="fetchBlogPost" />
    </b-overlay>

    <!-- sidebar -->
    <div
      slot="sidebar"
      class="blog-sidebar py-2 py-lg-0"
    >
      <!-- recent posts -->
      <b-overlay :show="recentsLoading">
        <div class="blog-recent-posts">
          <h6 class="section-label mb-75">
            Recent Posts
          </h6>
          
          <b-media
            v-for="(recentpost, index) in recentBlogPosts"
            :key="index"
            :class="index ? 'mt-2': ''"
            no-body
          >
            <b-media-aside class="mr-2">
              <b-link :href="`/admin/blog/${getValueFromSource(recentpost, 'slug')}`">
                <b-img
                  :src="getActualValueFromSource(recentpost, 'featured_image.path', require('@/assets/images/pages/blog-default.webp'))"
                  alt=""
                  width="100"
                  height="70"
                  rounded
                />
              </b-link>
            </b-media-aside>
            <b-media-body>
              <h6 class="blog-recent-post-title">
                <b-link
                  :href="`/admin/blog/${getValueFromSource(recentpost, 'slug')}`"
                  class="text-body-heading"
                >
                  {{ getValueFromSource(recentpost, 'title') }}
                </b-link>
              </h6>
              <span class="text-muted mb-0">
                {{ formatDate(getValueFromSource(recentpost, 'created'), 'MMM dd, yy') }}
              </span>
            </b-media-body>
          </b-media>
        </div>
      </b-overlay>
      <!--/ recent posts -->
    </div>
  </content-with-sidebar>
</template>

<script>
import {
  BOverlay, BAlert, BFormInput, BMedia, BMediaAside, BMediaBody, BImg, BLink,
  BCard, BRow, BCol, BBadge, BCardText, BButton
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import { kFormatter } from '@core/utils/filter'
import { get } from "lodash"

import ContentWithSidebar from '@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BlogDetails from "@/@core/components/shared/blogs/BlogDetails.vue"

export default {
  components: {
    BOverlay,
    BAlert,
    BFormInput,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BCard,
    BRow,
    BCol,
    BImg,
    BBadge,
    BButton,
    BCardText,
    ContentWithSidebar,

    BlogDetails
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      blogPost: {},
      recentBlogPosts: [],
      socialShareIcons: [
        'GithubIcon',
        'GitlabIcon',
        'FacebookIcon',
        'TwitterIcon',
        'LinkedinIcon',
      ],
      loading: false,
      recentsLoading: false,
    }
  },
  computed: {},
  created() {
    this.fetchBlogPost();
    this.fetchRecentPosts()
  },
  methods: {
    async fetchBlogPost() {
      try {
        this.loading = true; 

        const { slug } = this.$route.params;

        const request = await this.useJwt().sharedService.fetchBlogPost(slug);
        const { data } = request.data;

        this.blogPost = data;
      } catch (error) {
        this.blogPost = undefined
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async fetchRecentPosts() {
      try {
        this.recentsLoading = true; 

        const request = await this.useJwt().sharedService.fetchRecentBlogPosts();
        const { data } = request.data;

        this.recentBlogPosts = data;
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.recentsLoading = false;
      }
    },
    kFormatter,
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-blog.scss';
</style>
